<template>
  <div class="result-table">
    <div class="setting-nav-title">
      {{ $t("merchantManagement") }}
    </div>
    <div class="setting-tool">
      <div class="searchInput pull-left">
        <el-input v-model="searchUserString" class="input-with-select" clearable>
          <el-select v-model="select" slot="prepend" @change="changeSelect">
            <el-option :label="$t('enterprise')" value="1"></el-option>
            <el-option :label="$t('name')" value="2"></el-option>
          </el-select>
          <el-button slot="append" icon="el-icon-search" v-on:click="getList()"></el-button>
        </el-input>
      </div>
      <el-button class="confirm" style="float: right;" @click="addUser" v-permission="'Ent.EntUserAdd'">
        {{ $t("createNewEntUser") }}
      </el-button>
    </div>
    <el-table :data="tableData" v-loading="loading" @row-click="toDetail">
      <el-table-column :label="$t('status')">
        <template slot-scope="scope">
          <el-tooltip
            class="item"
            effect="dark"
            :content="$t('effective')"
            v-if="scope.row.isEffective"
            placement="top-start"
          >
            <svg-icon icon-class="success"></svg-icon>
          </el-tooltip>
          <el-tooltip class="item" effect="dark" :content="$t('uneffective')" v-else placement="top-start">
            <svg-icon icon-class="error"></svg-icon>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column prop="entDisplayName" :label="$t('enterprise')"> </el-table-column>
      <el-table-column prop="userFullName" :label="$t('name')"> </el-table-column>
      <el-table-column prop="roleName" :label="$t('authority')">
        <template slot-scope="scope">
          <div>
            {{ formatRoleName(scope.row.roleName) }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="store" :label="$t('manageStores')">
        <template slot-scope="scope">
          <div>{{ formatShopName(scope.row.displayShopInfo) }}</div>
        </template>
      </el-table-column>
      <el-table-column width="150">
        <template slot-scope="scope">
          <svg-icon
            icon-class="edit"
            class-name="handle-icon"
            @click.native.stop="edit(scope.row)"
            v-permission="'Ent.EntUserEdit'"
          ></svg-icon>
          <svg-icon
            icon-class="lock"
            class-name="handle-icon"
            @click.native.stop="changePassword(scope.row)"
            v-permission="'Ent.EntUserEdit'"
          ></svg-icon>
          <svg-icon icon-class="expand" class-name="handle-icon"></svg-icon>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      background
      layout=" prev, pager, next, sizes, total"
      :total="total"
      :page-size="currentPageSize"
      :page-sizes="[10, 15, 20]"
      :current-page="currentPage"
      @current-change="currentChange"
      @size-change="sizeChange"
      class="common-pagination"
    >
    </el-pagination>

    <side-layer :show-side-layer.sync="formDrawer" :wrapperClosable="false" @close="handleClose">
      <div v-loading="detailLoading">
        <div class="setting-nav-title">
          {{ formType === "add" ? $t("createNewEntUser") : $t("editUser") }}
        </div>
        <el-form ref="userform" :model="userform" label-position="top" v-if="!detailLoading" :rules="formRules">
          <el-row :gutter="10">
            <el-col :span="12">
              <el-form-item :label="$t('userName')" prop="userName">
                <el-input clearable v-model="userform.userName" :disabled="formType === 'edit'"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item :label="$t('name')" prop="userFullName">
                <el-input clearable v-model="userform.userFullName"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col>
              <el-form-item :label="$t('enterprise')" prop="userEnt">
                <el-select
                  filterable
                  :filter-method="filterList"
                  v-model="userform.userEnt"
                  style="width: 100%;"
                  v-loading="entListLoading"
                  element-loading-spinner="el-icon-loading"
                  @change="entListChange"
                  :disabled="disableEntSelect"
                >
                  <el-option
                    v-for="item in entList"
                    :key="item.enterpriseCode"
                    :label="item.enterpriseName + '(' + item.enterpriseCode + ')'"
                    :value="item.enterpriseCode"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="12">
              <el-form-item :label="$t('phoneNum')" prop="userPhoneNumber">
                <el-input clearable v-model="userform.userPhoneNumber"> </el-input>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item :label="$t('email')" prop="userEmail">
                <el-input clearable v-model="userform.userEmail"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="12" v-if="formType === 'add'">
              <el-form-item :label="$t('password')" prop="userPassword">
                <el-input clearable v-model="userform.userPassword" type="password" show-password> </el-input>
              </el-form-item>
            </el-col>

            <el-col :span="12" v-if="formType === 'add'">
              <el-form-item :label="$t('passwordRepeat')" prop="userPasswordRepeat">
                <el-input clearable v-model="userform.userPasswordRepeat" type="password" show-password> </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="12">
              <el-form-item :label="$t('status')" prop="isEffective">
                <el-radio :disabled="formType === 'add'" v-model="userform.isEffective" :label="true">{{
                  $t("effective")
                }}</el-radio>
                <el-radio :disabled="formType === 'add'" v-model="userform.isEffective" :label="false">{{
                  $t("uneffective")
                }}</el-radio>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item :label="$t('ForceModifyPassword')" prop="forceModifyPassword">
                <el-radio :disabled="formType === 'edit'" v-model="userform.forceModifyPassword" :label="true">{{
                  $t("on")
                }}</el-radio>
                <el-radio :disabled="formType === 'edit'" v-model="userform.forceModifyPassword" :label="false">{{
                  $t("off")
                }}</el-radio>
              </el-form-item>
            </el-col>

            <el-col :span="24">
              <el-form-item :label="$t('enableSystemMessage')" prop="enableSystemMessage">
                <el-radio
                  v-model="userform.enableSystemMessage"
                  :label="true"
                  @change="
                    e => {
                      userform.enableSystemEmail = e;
                    }
                  "
                  >{{ $t("on") }}</el-radio
                >
                <el-radio
                  v-model="userform.enableSystemMessage"
                  :label="false"
                  @change="
                    e => {
                      userform.enableSystemEmail = e;
                    }
                  "
                  >{{ $t("off") }}</el-radio
                >
              </el-form-item>
            </el-col>

            <el-col :span="24">
              <el-form-item :label="$t('enableSystemEmail')" prop="enableSystemEmail">
                <el-radio
                  :disabled="!userform.enableSystemMessage"
                  v-model="userform.enableSystemEmail"
                  :label="true"
                  >{{ $t("on") }}</el-radio
                >
                <el-radio
                  :disabled="!userform.enableSystemMessage"
                  v-model="userform.enableSystemEmail"
                  :label="false"
                  >{{ $t("off") }}</el-radio
                >
              </el-form-item>
            </el-col>
          </el-row>
          <div style="text-align: right;">
            <el-button class="confirm" @click="userFormSubmit" :loading="submitLoading" :disabled="submitLoading">
              {{ $t("submit") }}
            </el-button>
          </div>
        </el-form>
      </div>
    </side-layer>

    <side-layer :show-side-layer.sync="detailDrawer">
      <div>
        <div class="setting-nav-title">{{ $t("userDetail") }}</div>

        <div class="name-value" style="margin-bottom: 20px;">
          <table cellspacing="0" cellpadding="0" border="0">
            <tr>
              <td class="name">
                {{ $t("userName") }}
              </td>
              <td class="value">
                {{ detailInfo.userName }}
              </td>
            </tr>

            <tr>
              <td class="name">
                {{ $t("name") }}
              </td>
              <td class="value">
                {{ detailInfo.userFullName }}
              </td>
            </tr>
            <tr>
              <td class="name">
                {{ $t("enterprise") }}
              </td>
              <td class="value">
                {{ detailInfo.entDisplayName }}
              </td>
            </tr>
            <tr>
              <td class="name">
                {{ $t("status") }}
              </td>
              <td class="value">
                {{ detailInfo.isEffective ? $t("effective") : $t("uneffective") }}
              </td>
            </tr>

            <tr>
              <td class="name">
                {{ $t("authority") }}
              </td>
              <td class="value">
                {{ formatRoleName(detailInfo.roleName) }}
              </td>
            </tr>

            <tr>
              <td class="name">
                {{ $t("phoneNum") }}
              </td>
              <td class="value">
                {{ detailInfo.userPhoneNum }}
              </td>
            </tr>
            <tr>
              <td class="name">
                {{ $t("email") }}
              </td>
              <td class="value">
                {{ detailInfo.userEmail }}
              </td>
            </tr>
          </table>
        </div>

        <div class="setting-nav-title" v-permission="'Ent.EntUserDelete'">
          {{ $t("userNouseAndDel") }}
        </div>

        <div class="explain" v-permission="'Ent.EntUserDelete'">
          {{ $t("userLongTimeNouse") }}
        </div>

        <div style="margin-top: 16px;">
          <el-button class="delete" @click="removeDialog = true" v-permission="'Ent.EntUserDelete'">{{
            $t("delete")
          }}</el-button>
        </div>
      </div>
    </side-layer>

    <side-layer :show-side-layer.sync="passwordDrawer">
      <div class="setting-nav-title">{{ $t("userPasswordChange") }}</div>
      <el-form
        ref="editPassword"
        :model="editPassword"
        :rules="editPasswordRules"
        label-width="100px"
        label-position="top"
      >
        <el-form-item :label="$t('name')">
          <el-input v-bind:value="editPassword.userFullName" disabled></el-input>
        </el-form-item>
        <el-form-item :label="$t('newPassword')" prop="userPassword">
          <el-input
            v-model="editPassword.userPassword"
            type="password"
            clearable
            show-password
            autocomplete="off"
            :placeholder="$t('passwordInputHint')"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('passwordRepeat')" prop="userPasswordRepeat">
          <el-input
            v-model="editPassword.userPasswordRepeat"
            type="password"
            clearable
            show-password
            autocomplete="off"
            :placeholder="$t('passwordInputHint')"
          >
          </el-input>
        </el-form-item>
      </el-form>

      <div>
        <el-button
          class="delete dialog-btn"
          type="primary"
          @click="editPasswordSubmit"
          :loading="changePasswordLoading"
          :disabled="changePasswordLoading"
        >
          {{ $t("savePassword") }}
        </el-button>
      </div>
    </side-layer>

    <modal-dialog :show-dialog.sync="removeDialog" :dialog-title="$t('userDeleteConfirm')">
      <div class="user-remove-table">
        <table cellspacing="0" cellpadding="0" border="0">
          <tr>
            <td class="name">
              {{ $t("userName") }}
            </td>
            <td class="value">
              {{ detailInfo.userName }}
            </td>
          </tr>

          <tr>
            <td class="name">
              {{ $t("name") }}
            </td>
            <td class="value">
              {{ detailInfo.userFullName }}
            </td>
          </tr>
          <tr>
            <td class="name">
              {{ $t("enterprise") }}
            </td>
            <td class="value">
              {{ detailInfo.entDisplayName }}
            </td>
          </tr>
        </table>
      </div>

      <template v-slot:footer>
        <el-button class="cancel dialog-btn" type="primary" @click="cancel">
          {{ $t("cancel") }}
        </el-button>
        <el-button
          class="delete dialog-btn"
          type="primary"
          @click="userDelete"
          :loading="deleteLoading"
          :disabled="deleteLoading"
        >
          {{ $t("delete") }}
        </el-button>
      </template>
    </modal-dialog>
  </div>
</template>

<script>
import { getSysUserList, getSysEntList, userDelete, addSysUser, modifySysUser, resetPassword } from "api/user";
import { getSessionStorage } from "@/utils/storage";
import { cloneDeep } from "lodash";
import pagination from "@/mixins/pagination";
import { validatePassWord, checkDifferentPassword, validatePhone } from "utils";
export default {
  mixins: [pagination],
  data() {
    var validatePass = (rule, value, callback) => {
      if (!validatePassWord(value)) {
        callback(new Error(this.$t("invalidPasswordFormat")));
      } else {
        if (this.userform.userPasswordRepeat !== "") {
          this.$refs.userform.validateField("userPasswordRepeat");
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (!validatePassWord(value)) {
        callback(new Error(this.$t("invalidPasswordFormat")));
      }
      if (!checkDifferentPassword(this.userform.userPassword, value)) {
        callback(new Error(this.$t("differentPasswordInput")));
      } else {
        callback();
      }
    };
    var validateEditPass = (rule, value, callback) => {
      if (!validatePassWord(value)) {
        callback(new Error(this.$t("invalidPasswordFormat")));
      } else {
        if (this.editPassword.userPasswordRepeat !== "") {
          this.$refs.editPassword.validateField("userPasswordRepeat");
        }
        callback();
      }
    };
    var validateEditPass2 = (rule, value, callback) => {
      if (!validatePassWord(value)) {
        callback(new Error(this.$t("invalidPasswordFormat")));
      }
      if (!checkDifferentPassword(this.editPassword.userPassword, value)) {
        callback(new Error(this.$t("differentPasswordInput")));
      } else {
        callback();
      }
    };
    var validatePhoneNum = (rule, value, callback) => {
      if (!validatePhone(value)) {
        callback(new Error(this.$t("userContactInputErr")));
      } else {
        callback();
      }
    };
    return {
      placeholder: this.$t("name"),
      formRules: {
        userName: { required: true, message: this.$t("userNameInputErr"), trigger: "blur" },
        userFullName: { required: true, message: this.$t("userFullNameInputErr"), trigger: "blur" },
        userEnt: { required: true, message: this.$t("manageStoreInputErr"), trigger: "change" },
        roleId: { required: true, message: this.$t("userRightInputErr"), trigger: "change" },
        userPhoneNumber: [
          { required: true, message: this.$t("userContactInputErr"), trigger: "blur" },
          { validator: validatePhoneNum, trigger: ["blur", "change"] }
        ],
        userEmail: [
          { required: true, message: this.$t("userEmailInputErr"), trigger: "blur" },
          { type: "email", message: this.$t("userEmailInputErr"), trigger: ["blur", "change"] }
        ],
        userPassword: [
          { required: true, message: this.$t("userPWDInputErr"), trigger: "blur" },
          { validator: validatePass, trigger: "blur" }
        ],
        userPasswordRepeat: [
          { required: true, message: this.$t("userPWDReaptInputErr"), trigger: "blur" },
          { validator: validatePass2, trigger: "blur" }
        ],
        // isEffective: { required: true, message: this.$t("userStausInputErr"), trigger: "blur" },
        // forceModifyPassword: { required: true, message: this.$t("userStausInputErr"), trigger: "blur" },
        forcePasswordExpired: { required: true, message: this.$t("userStausInputErr"), trigger: "blur" },
        passwordExpirationDays: { required: true, message: this.$t("userStausInputErr"), trigger: "blur" }
      },
      editPasswordRules: {
        userPassword: [
          { required: true, message: this.$t("userPWDInputErr"), trigger: "blur" },
          { validator: validateEditPass, trigger: "blur" }
        ],
        userPasswordRepeat: [
          { required: true, message: this.$t("userPWDReaptInputErr"), trigger: "blur" },
          { validator: validateEditPass2, trigger: "blur" }
        ]
      },
      tableData: [],
      loading: false,
      formDrawer: false,
      deleteLoading: false,
      detailLoading: false,
      changePasswordLoading: false,
      userform: {},

      formType: "",
      submitLoading: false,

      detailDrawer: false,
      detailInfo: {},
      removeDialog: false,
      sysEntList: [],
      entList: [],
      entListLoading: false,
      passwordDrawer: false,
      disableEntSelect: false,
      userId: null,
      userInfo: {},

      editPassword: {
        roleId: "",
        userEmail: "",
        isEffective: true,
        userFullName: "",
        userName: "",
        userPhoneNumber: "",
        userEnt: [],
        userPassword: "",
        userPasswordRepeat: ""
      },
      searchUserString: "",
      select: "1",
      entName: "",
      userFullName: ""
    };
  },
  mounted() {
    this.userInfo = JSON.parse(getSessionStorage("userInfo"));
    this.getList();
  },
  methods: {
    handleClose() {
      console.log("close");
      this.$refs.userform.clearValidate();
    },
    changeSelect(value) {
      if (value === "1") {
        this.userFullName = "";
        this.entName = this.searchUserString.trim();
      } else {
        this.entName = "";
        this.userFullName = this.searchUserString.trim();
      }
    },
    cancel() {
      this.removeDialog = false;
    },
    // 单独处理角色名称
    formatRoleName(roleName) {
      let formatRoleName = "";
      switch (roleName) {
        case "SuperAdmin":
          formatRoleName = this.$t("superAdmin");
          break;
        case "Administrator":
          formatRoleName = this.$t("administrator");
          break;
        case "Manager":
          formatRoleName = this.$t("manager");
          break;
        case "User":
          formatRoleName = this.$t("userRole");
          break;
        default:
          formatRoleName = roleName;
          break;
      }
      return formatRoleName;
    },
    //  单独处理店铺名称
    formatShopName(shopName) {
      let formatShopName = "";
      switch (shopName) {
        case "noshop":
          formatShopName = this.$t("noshop");
          break;
        case "allshop":
          formatShopName = this.$t("allshop");
          break;
        default:
          formatShopName = shopName;
      }
      return formatShopName;
    },
    getList(pageIndex = 1, pageSize = this.currentPageSize) {
      let that = this;
      this.loading = true;
      this.currentPage = pageIndex;
      if (this.select === "1") {
        this.userFullName = "";
        this.entName = this.searchUserString.trim();
      } else {
        this.entName = "";
        this.userFullName = this.searchUserString.trim();
      }
      getSysUserList({
        EntName: this.entName,
        UserFullName: this.userFullName,
        pageSize,
        pageIndex
      }).then(response => {
        this.loading = false;
        if (response.resultStatus === "Success" && response.statusCode === 200) {
          that.tableData = response.result;
          that.total = response.totalCount;
        }
      });
    },
    //获取企业列表
    async getEntList() {
      this.entListLoading = true;
      let response = await getSysEntList();
      this.entListLoading = false;
      if (response.resultStatus === "Success" && response.statusCode === 200) {
        this.sysEntList = response.result;
        this.entList = response.result.slice(0, 50);
      }
    },
    //解决卡顿问题
    filterList(query = "") {
      let arr = this.sysEntList.filter(item => {
        return item.enterpriseName.includes(query) || item.enterpriseCode.includes(query);
      });
      if (arr.length > 50) {
        this.entList = arr.slice(0, 50);
      } else {
        this.entList = arr;
      }
    },
    //打开新增UI
    addUser() {
      this.formDrawer = true;
      this.formType = "add";
      this.detailLoading = true;
      this.disableEntSelect = false;
      this.getEntList();
      this.userform = {
        userEmail: "",
        isEffective: true,
        userFullName: "",
        userName: "",
        userPhoneNumber: "",
        userEnt: "",
        userPassword: "",
        userPasswordRepeat: "",
        forceModifyPassword: true,
        enableSystemMessage: true,
        enableSystemEmail: true
      };
      this.detailLoading = false;
    },
    //打开编辑UI
    async edit(row) {
      this.formDrawer = true;
      this.shopListMuti = true;
      this.formType = "edit";
      this.disableEntSelect = true;
      this.detailLoading = true;
      this.userform = {
        userEmail: "",
        isEffective: true,
        userFullName: "",
        userName: "",
        userPhoneNumber: "",
        userEnt: "",
        entCode: "",
        userPassword: "",
        userPasswordRepeat: "",
        forceModifyPassword: true,
        enableSystemMessage: true,
        enableSystemEmail: true
      };
      this.userform.userId = row.userId;
      this.userform.isEffective = row.isEffective;
      this.userform.userEmail = row.userEmail;
      this.userform.userName = row.userName;
      this.userform.userFullName = row.userFullName;
      this.userform.userPhoneNumber = row.userPhoneNum;
      this.userform.userEnt = row.entDisplayName;
      this.userform.entCode = row.entCode;
      this.userform.forceModifyPassword = row.forceModifyPassword;
      this.userform.enableSystemMessage = row.enableSystemMessage;
      this.userform.enableSystemEmail = row.enableSystemEmail;
      this.detailLoading = false;
    },
    changePassword(row) {
      this.passwordDrawer = true;
      this.formType = "changepassword";
      this.detailLoading = true;
      this.editPassword = {
        userId: "",
        userFullName: "",
        userPassword: "",
        userPasswordRepeat: ""
      };
      this.editPassword.userId = row.userId;
      this.editPassword.userFullName = row.userFullName;
      this.detailLoading = false;

      this.$nextTick(() => {
        this.$refs["editPassword"].clearValidate();
      });
    },

    toDetail(row) {
      this.detailDrawer = true;
      this.detailInfo = row;
    },

    userDelete() {
      this.deleteLoading = true;
      userDelete({ userId: this.detailInfo.userId }).then(response => {
        this.deleteLoading = false;
        if (response.resultStatus === "Success" && response.statusCode === 200) {
          this.$message.success(this.$t("success"));
          this.removeDialog = false;
          this.detailDrawer = false;
          this.getList(this.currentPage);
        } else {
          this.$message.error(this.$t("fail"));
        }
      });
    },
    editPasswordSubmit() {
      this.$refs["editPassword"].validate(async valid => {
        if (valid) {
          let data = cloneDeep(this.editPassword);
          data.newPassword = this.editPassword.userPassword;
          this.changePasswordLoading = true;
          resetPassword(data).then(response => {
            this.changePasswordLoading = false;
            if (response.resultStatus === "Success" && response.statusCode === 200) {
              this.$message.success(this.$t("success"));
              this.passwordDrawer = false;
            } else {
              this.$message.error(this.$t("fail"));
            }
          });
        } else {
          return false;
        }
      });
    },
    userFormSubmit() {
      this.$refs["userform"].validate(valid => {
        if (valid) {
          let action = this.formType === "edit" ? modifySysUser : addSysUser;
          let data = cloneDeep(this.userform);
          this.submitLoading = true;
          action(data).then(response => {
            this.submitLoading = false;
            if (response.resultStatus === "Success" && response.statusCode === 200) {
              this.formDrawer = false;
              this.getList(this.currentPage);
              this.$message.success(this.$t("success"));
            } else {
              this.$message.error(this.$t("fail"));
            }
          });
        } else {
          return false;
        }
      });
    },
    entListChange(value) {
      this.userform.userEnt = value;
    }
  },
  watch: {
    $route(to, from) {
      console.log("rchange", to);
      // if (to.name == "PointsRules") {
      //   this.activeIndex = "/setting/shop";
      // }
      // if (to.name == "SetPayment") {
      //   this.initData();
      // }
      this.userInfo = JSON.parse(getSessionStorage("userInfo"));
      this.getList();
    }
  }
};
</script>
<style>
.input-with-select .el-input .el-input__inner {
  width: 130px;
}
</style>
<style lang="scss" scoped>
.store-list-item {
  line-height: 30px;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.user-remove-table {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-left: 1px solid rgba(0, 0, 0, 0.12);
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  width: 100%;

  table {
    width: 100%;
    table-layout: fixed;
  }

  td {
    padding: 10px 16px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);

    word-wrap: break-word;
  }

  .name {
    vertical-align: middle;
  }
}
</style>
